/* eslint-disable global-require */
/* eslint-disable array-callback-return */
import {
  BRow, BCol, BCard, BForm, BFormGroup,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { YEAR_MONTH_DAY } from '@/libs/filterDate'
import moment from 'moment'
import {
  today,
  last7,
  last2,
  last30,
  last60,
  firstDateOfMonth,
  lastDateOfMonth,
  komshipDate,
  last150,
} from '@/store/helpers'
import BadgeJuragan from '@/views/components/badge/BadgeJuragan.vue'
import BadgeUrgent from '@/views/components/badge/BadgeUrgent.vue'
import BadgeSaudagar from '@/views/components/badge/BadgeSaudagar.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    vSelect,
    DateRangePicker,
    ValidationProvider,
    ValidationObserver,
    BadgeJuragan,
    BadgeUrgent,
    BadgeSaudagar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loadingDataTable: true,
      loading: true,
      sortIconNeutral: `<svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.3002 7.82617L5.58353 10.501C5.2627 10.8169 4.7377 10.8169 4.41686 10.501L1.7002 7.82617" stroke="#222222" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.3002 4.17351L5.58353 1.49864C5.2627 1.18274 4.7377 1.18274 4.41686 1.49864L1.7002 4.17351" stroke="#222222" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `,
      sortIconAsc: `<svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.3002 0.826172L5.58353 3.50104C5.2627 3.81694 4.7377 3.81694 4.41686 3.50104L1.7002 0.826172" stroke="#222222" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `,
      SortIconDesc: `<svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.3002 3.67351L5.58353 0.998642C5.2627 0.682744 4.7377 0.682744 4.41686 0.998642L1.7002 3.67351" stroke="#222222" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `,
      sortBy: null,
      sortDesc: false,

      fieldsTicket: [
        {
          key: 'ticket_no',
          label: 'No. Tiket',
          trClass: 'border-top-0',
          thClass: 'text-center',
          tdClass: 'text-left min-w-[200px] px-1',
        },
        {
          key: 'no_resi',
          label: 'Nomor Resi',
          trClass: 'border-top-0',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'shipping',
          label: 'Ekspedisi',
          trClass: 'border-top-0',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'brand_name',
          label: 'Seller',
          trClass: 'border-top-0',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Jenis Tiket',
          trClass: 'border-top-0',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
        {
          key: 'ticket_status',
          label: 'Status Tiket',
          trClass: 'border-top-0',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
        {
          key: 'delay_action',
          label: 'Delay Action',
          trClass: 'border-top-0',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'date_created',
          label: 'Waktu Dibuat',
          trClass: 'border-top-0',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
        {
          key: 'date_updated',
          label: 'Waktu Diupdate',
          trClass: 'border-top-0',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
      ],
      itemsTicket: [],
      itemsResi: [],
      timeResponse: [],
      modes: 'single',
      selectMode: 'single',
      selected: true,

      // Date range picker
      YEAR_MONTH_DAY,
      picker: {
        startDate: firstDateOfMonth,
        endDate: lastDateOfMonth,
      },
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
        monthNames: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'Mei',
          'Jun',
          'Jul',
          'Agu',
          'Sep',
          'Okt',
          'Nov',
          'Des',
        ],
      },
      ranges: {
        'Semua ': [komshipDate, today],
        '7 Hari Terakhir': [last7, today],
        '30 Hari Terakhir': [last30, today],
        'Bulan Ini': [firstDateOfMonth, today],
      },
      rangesActivity: {
        'Hari ini ': [today, today],
        'Kemarin ': [last2, last2],
        '7 Hari Terakhir': [last7, today],
        'Bulan Ini': [firstDateOfMonth, today],
        'Custom Range': [null, null],
      },
      today,
      last7,
      last2,
      last30,
      firstDateOfMonth,
      lastDateOfMonth,
      komshipDate,
      imageFile: null,
      imageInitialFile: null,
      itemsImageInitialFile: [],
      belumDiProses: 0,
      perluTindakLanjut: 0,
      sedangDiProses: 0,
      // Validation
      required,

      // Store
      itemsNoResi: null,
      noResi: null,
      customerName: '',
      ticketTypeItems: [],
      ticketType: null,
      description: '',
      file: null,

      // Filter
      ticketStatus: [],
      ticketStatusItems: [
        {
          label: 'Menunggu Tindak Lanjut',
          value: 0,
          onCheck: false,
        },
        {
          label: 'Belum Diproses',
          value: 1,
          onCheck: false,
        },
        {
          label: 'Sedang Diproses',
          value: 2,
          onCheck: false,
        },
        {
          label: 'Selesai',
          value: 3,
          onCheck: false,
        },
      ],
      shippingItems: [],
      fontClassTicketStatus: {
        color: 'salmon',
      },
      search: '',
      filterTicketType: [],
      dateRange: {
        startDate: last60,
        endDate: today,
      },
      dateRangeUpdate: {
        startDate: last150,
        endDate: today,
      },
      dateRangeActivity: {
        startDate: today,
        endDate: today,
      },
      // Pagination
      currentPage: 1,
      totalRows: 0,
      optionsPage: [250, 500],
      totalPerPage: 250,
      dataCountShippment: {},
      loadingCountShippment: true,
      priorityItems: [
        {
          label: 'User Prioritas',
          value: 'UserPrioritas',
          isChecked: false,
        },
        {
          label: 'Urgent',
          value: 'Urgent',
          isChecked: false,
        },
      ],

      dropdownFilter: false,
      filterRequest: false,
      countPrioritas: 0,
      countUrgent: 0,
      countRequest: 0,
      expedition: [],
    }
  },
  computed: {
    ...mapState('filter', ['expeditionList']),
  },
  watch: {
    dateRangeActivity: {
      handler() {
        this.fetchTimeResponse()
      },
    },
    currentPage: {
      handler() {
        this.fetchTicket()
      },
    },
  },
  beforeMount() {
    this.ticketStatusItems.map((val, index) => {
      if (val.onCheck === true) {
        this.ticketStatus.push(val.value)
      }
    })
  },
  mounted() {
    if (this.expeditionList.length === 0) {
      this.$store.dispatch('filter/getUpdateExpedition')
    }
    this.shippingItems = this.expeditionList.map(item => item.shipping_name)
    this.fetchTicketType()
    this.formatDate()
    this.fetchTicketAll()
    this.countTicketPartnerWithSession()
    this.ticketTypeItems.map(val => {
      if (val.onCheck === true) {
        this.filterTicketType.push(val.id)
      }
    })
  },
  methods: {
    countFilter(params) {
      if (this.priorityItems[1].isChecked) {
        this.$http_komship
          .get('/v1/ticket-admin/count-urgent-admin', { params })
          .then(res => {
            const { data } = res.data
            this.countUrgent = data.total
          })
      }
      if (this.filterRequest) {
        this.$http_komship
          .get('/v1/ticket-admin/count-request-urgent-admin', { params })
          .then(res => {
            const { data } = res.data
            this.countRequest = data.total
          })
      }
      if (this.priorityItems[0].isChecked) {
        this.$http_komship
          .get('/v1/ticket-admin/count-user-prioritas-admin', { params })
          .then(res => {
            const { data } = res.data
            this.countPrioritas = data.total
          })
      }
    },
    setFilter(value) {
      this.priorityItems.forEach((item, index) => {
        if (item.value === value) {
          this.priorityItems[index].isChecked = !item.isChecked
        }
      })
      this.fetchTicket()
    },
    setFilterRequest() {
      this.filterRequest = !this.filterRequest
      if (this.filterRequest) {
        const findIndexProccessed = this.ticketStatusItems.findIndex(item => item.value === 1)
        const findIndexUnProccessed = this.ticketStatusItems.findIndex(item => item.value === 2)
        this.ticketStatusItems[findIndexProccessed].onCheck = true
        this.ticketStatusItems[findIndexUnProccessed].onCheck = true
        this.filterTicketByStatus(this.ticketStatusItems[findIndexProccessed])
        this.filterTicketByStatus(this.ticketStatusItems[findIndexUnProccessed])
      } else {
        const findIndexProccessed = this.ticketStatusItems.findIndex(item => item.value === 1)
        const findIndexUnProccessed = this.ticketStatusItems.findIndex(item => item.value === 2)
        this.ticketStatusItems[findIndexProccessed].onCheck = false
        this.ticketStatusItems[findIndexUnProccessed].onCheck = false
        this.filterTicketByStatus(this.ticketStatusItems[findIndexProccessed])
        this.filterTicketByStatus(this.ticketStatusItems[findIndexUnProccessed])
      }
      this.fetchTicket()
    },
    imgPlaceholder(e) {
      e.target.src = require('@/assets/images/avatars/image-null.png')
    },
    countTicketPartnerWithSession() {
      const session = localStorage.getItem('count_ticket_partner')
      if (session) {
        const countTicketPartner = JSON.parse(session)
        const { data } = countTicketPartner
        const expired = moment(countTicketPartner.exp)
        if (moment().isAfter(expired)) {
          this.getCountShippment()
        } else {
          this.dataCountShippment = data
          this.loadingCountShippment = false
        }
      } else this.getCountShippment()

      const sessionTimeRespone = localStorage.getItem('count_time_response')
      if (sessionTimeRespone) {
        const countTimeResponse = JSON.parse(sessionTimeRespone)
        const { data } = countTimeResponse
        const expired = moment(countTimeResponse.exp)
        if (moment().isAfter(expired)) {
          this.fetchTimeResponse()
        } else {
          this.timeResponse = data
          this.loading = false
        }
      } else this.fetchTimeResponse()
    },
    async fetchTimeResponse() {
      this.loading = true
      const params = {
        start_date: this.formatDateParams(this.dateRangeActivity.startDate),
        end_date: this.formatDateParams(this.dateRangeActivity.endDate),
      }
      await this.$http_komship
        .get('/v1/ticket-admin/time-response', {
          params,
        })
        .then(res => {
          const { data } = res.data
          localStorage.setItem(
            'count_time_response',
            JSON.stringify({ exp: moment().add(1, 'hours'), data }),
          )
          this.timeResponse = data
          this.loading = false
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err,
                variant: 'danger',
              },
            },
            2000,
          )
          this.loading = false
        })
    },
    async fetchTicketAll() {
      this.loadingDataTable = true
      const params = {}
      Object.assign(params, {
        start_date: this.formatDateParams(this.dateRange.startDate),
      })
      Object.assign(params, {
        end_date: this.formatDateParams(this.dateRange.endDate),
      })
      await this.$http_komship.get('/v1/ticket-admin/list', { params })
        .then(response => {
          if (response.data.code !== 400) {
            const { data } = response.data.data
            this.itemsTicket = data
            this.totalRows = response.data.data.total
            this.loadingDataTable = false
            this.dropdownFilter = false
            this.countFilter(params)
            if (this.expedition.length !== 0) {
              this.getCountShippment()
            }
          } else {
            this.itemsTicket = []
            this.loadingDataTable = false
          }
        })
        .catch(err => {
          this.itemsTicket = []
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.data.message,
              variant: 'danger',
            },
          }, 2000)
          this.loadingDataTable = false
        })
    },
    async fetchTicket() {
      this.loadingDataTable = true
      this.search = ''
      const params = {}
      Object.assign(params, { ticket_status: this.ticketStatus.join() })
      Object.assign(params, { shipping: this.expedition.join(',') })
      Object.assign(params, { ticket_type: this.filterTicketType.join() })
      Object.assign(params, {
        start_date: this.formatDateParams(this.dateRange.startDate),
      })
      Object.assign(params, {
        end_date: this.formatDateParams(this.dateRange.endDate),
      })
      Object.assign(params, {
        update_start_date: this.formatDateParams(
          this.dateRangeUpdate.startDate,
        ),
      })
      Object.assign(params, {
        update_end_date: this.formatDateParams(this.dateRangeUpdate.endDate),
      })
      Object.assign(params, { total_per_page: this.totalPerPage })
      Object.assign(params, { page: this.currentPage })
      if (this.filterRequest) {
        Object.assign(params, { request_urgent: 1 })
      }
      const priority = this.priorityItems.filter(item => item.isChecked).map(item => item.value).join(',')
      if (priority !== '') {
        Object.assign(params, { prioritas: priority })
      }

      await this.$http_komship
        .get('/v1/ticket-admin/list', {
          params,
        })
        .then(response => {
          const { data } = response.data.data
          this.itemsTicket = data
          this.totalRows = response.data.data.total
          this.loadingDataTable = false
          this.dropdownFilter = false
          this.countFilter(params)
          if (this.expedition.length !== 0) {
            this.getCountShippment()
          }
        })
        .catch(err => {
          this.itemsTicket = []
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err.response.data.message,
                variant: 'danger',
              },
            },
            2000,
          )
          this.loadingDataTable = false
        })
    },
    async fetchTicketType() {
      await this.$http_komship
        .get('/v1/ticket-admin/ticket-type/list')
        .then(response => {
          const { data } = response.data
          this.ticketTypeItems = data
          for (let x = 0; x < this.ticketTypeItems.length; x += 1) {
            Object.assign(this.ticketTypeItems[x], { onCheck: false })
          }
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err,
                variant: 'danger',
              },
            },
            2000,
          )
          return []
        })
    },
    async getCountShippment() {
      this.loadingCountShippment = true
      const params = {}
      Object.assign(params, { shipping: this.expedition.join(',') })
      await this.$http_komship.get('/v1/ticket-admin/count', {
        params,
      })
        .then(res => {
          const { data } = res.data
          localStorage.setItem(
            'count_ticket_partner',
            JSON.stringify({ exp: moment().add(1, 'hours'), data }),
          )
          this.dataCountShippment = data
          this.loadingCountShippment = false
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          }, 2000)
          this.loadingCountShippment = false
        })
    },
    async searchData() {
      this.clearFilter()
      const params = {}
      if (this.search) Object.assign(params, { search: this.search })
      await this.$http_komship
        .get('/v1/ticket-admin/list', {
          params,
        })
        .then(res => {
          const { data } = res.data.data
          this.itemsTicket = data
          this.totalRows = res.data.data.total
          this.loadingDataTable = false
          this.dropdownFilter = false
        })
        .catch(err => {
          this.itemsTicket = []
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err.response.data.message,
                variant: 'danger',
              },
            },
            2000,
          )
          this.loadingDataTable = false
        })
    },
    onRowSelected(data) {
      this.selected = data
      const url = this.$router.resolve({
        path: `/tiket/detail/${data[0].id}`,
        params: { data_tiket: data },
      })
      window.open(url.href, '_blank')
    },
    formatDate(d) {
      const ds = moment(d)
        .format('HH:mm D/MM/YYYY')
      const date = ds.split(' ')
      const newDate = date[1].split('/')
      const hour = `${date[0]} WIB`
      let bulan = ''

      // eslint-disable-next-line default-case
      switch (newDate[1]) {
        case '01':
          bulan = 'Januari'
          break
        case '02':
          bulan = 'Februari'
          break
        case '03':
          bulan = 'Maret'
          break
        case '04':
          bulan = 'April'
          break
        case '05':
          bulan = 'Mei'
          break
        case '06':
          bulan = 'Juni'
          break
        case '07':
          bulan = 'Juli'
          break
        case '08':
          bulan = 'Agustus'
          break
        case '09':
          bulan = 'September'
          break
        case '10':
          bulan = 'Oktober'
          break
        case '11':
          bulan = 'November'
          break
        case '12':
          bulan = 'Desember'
          break
      }

      return {
        hour: `${hour}`,
        date: `${newDate[0]} ${bulan} ${newDate[2]} `,
      }
    },
    formatDateParams(d) {
      return moment(d)
        .format('YYYY-MM-DD')
    },
    filterTicketByStatus(data) {
      const findIndexObj = this.ticketStatusItems.findIndex(
        items => items.value === data.value,
      )
      const findObj = this.ticketStatus.findIndex(items => items === data.value)
      if (!this.ticketStatus.includes(data.value)) {
        if (this.ticketStatusItems[findIndexObj].onCheck === true) {
          this.ticketStatus.push(data.value)
        }
      }

      if (this.ticketStatus.includes(data.value) && !this.ticketStatusItems[findIndexObj].onCheck) {
        this.ticketStatus.splice(findObj, 1)
      }
    },
    filterByTicketType(data) {
      const findIndexObj = this.ticketTypeItems.findIndex(
        items => items.id === data.id,
      )
      const findObj = this.filterTicketType.findIndex(
        items => items === data.id,
      )
      if (this.ticketTypeItems[findIndexObj].onCheck === true) {
        this.filterTicketType.push(data.id)
      } else {
        this.filterTicketType.splice(findObj, 1)
      }
    },
    statusTicketClass(data) {
      let resultVariant = ''
      if (data === 1) {
        resultVariant = 'font-bold text-red-500'
      } else if (data === 2) {
        resultVariant = 'font-bold text-warning'
      } else if (data === 3) {
        resultVariant = 'font-bold text-success'
      } else if (data === 4) {
        resultVariant = 'font-bold text-secondary'
      }
      return resultVariant
    },
    checkDelayAction(value) {
      const data = value.delay_action
      if (data === null) {
        return '-'
      }
      return data
    },
    clearFilter() {
      if (this.search === '') {
        this.fetchTicketAll()
      }
      this.priorityItems = this.priorityItems.map(item => ({ ...item, isChecked: false }))
      this.ticketTypeItems = this.ticketTypeItems.map(item => ({ ...item, onCheck: false }))
      this.ticketStatusItems = this.ticketStatusItems.map(item => ({ ...item, onCheck: false }))
      this.expedition = []
      this.filterTicketType = []
      this.ticketStatus = []
      this.dateRange = {
        startDate: last60,
        endDate: today,
      }
      this.dateRangeUpdate = {
        startDate: last150,
        endDate: today,
      }
      this.filterRequest = false
    },
    convertTicketStatus(data) {
      let result = ''
      if (data === 0) {
        result = 'Perlu Tindak Lanjut'
      } else if (data === 1) {
        result = 'Belum Diproses'
      } else if (data === 2) {
        result = 'Sedang Diproses'
      } else if (data === 3) {
        result = 'Selesai'
      } else if (data === 4) {
        result = 'Batal'
      }
      return result
    },
    clearFieldTicket() {
      this.noResi = null
      this.itemsNoResi = null
      this.customerName = ''
      this.ticketType = null
      this.description = ''
      this.itemsImageInitialFile = []
    },
    setPerPage(page) {
      this.totalPerPage = page
      this.fetchTicket()
    },
    labelFilter(data) {
      return data.toString()
    },
    handleColorPercentage(value) {
      const numericValue = parseFloat(value)
      if (numericValue >= 90) {
        return 'text-[#34A770]'
      }
      return 'text-[#E31A1A]'
    },
  },
}

/* eslint-disable global-require */
/* eslint-disable array-callback-return */
import {
  BRow, BCol, BCard, BForm, BFormGroup,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { YEAR_MONTH_DAY } from '@/libs/filterDate'
import moment from 'moment'
import {
  today,
  last7,
  last2,
  last30,
  last60,
  firstDateOfMonth,
  lastDateOfMonth,
  komshipDate,
  last150,
} from '@/store/helpers'

export default {
  props: {
    isCollaborator: {
      default: false,
      type: Boolean,
    },
  },
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    vSelect,
    DateRangePicker,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loadingDataTable: true,
      loading: true,
      sortIconNeutral: `<svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.3002 7.82617L5.58353 10.501C5.2627 10.8169 4.7377 10.8169 4.41686 10.501L1.7002 7.82617" stroke="#222222" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.3002 4.17351L5.58353 1.49864C5.2627 1.18274 4.7377 1.18274 4.41686 1.49864L1.7002 4.17351" stroke="#222222" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `,
      sortIconAsc: `<svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.3002 0.826172L5.58353 3.50104C5.2627 3.81694 4.7377 3.81694 4.41686 3.50104L1.7002 0.826172" stroke="#222222" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `,
      SortIconDesc: `<svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.3002 3.67351L5.58353 0.998642C5.2627 0.682744 4.7377 0.682744 4.41686 0.998642L1.7002 3.67351" stroke="#222222" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `,
      sortBy: null,
      sortDesc: false,

      fieldsTicket: [
        {
          key: 'no_ticket',
          label: 'No. Tiket',
          trClass: 'border-top-0',
          thClass: 'text-center',
          tdClass: 'text-left min-w-[200px] px-1',
        },
        {
          key: 'awb',
          label: 'Nomor Resi',
          trClass: 'border-top-0',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'shipper',
          label: 'Ekspedisi',
          trClass: 'border-top-0',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'customer',
          label: 'Customer',
          trClass: 'border-top-0',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
        {
          key: 'type_ticket',
          label: 'Jenis Tiket',
          trClass: 'border-top-0',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
        {
          key: 'status_ticket',
          label: 'Status Tiket',
          trClass: 'border-top-0',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
        {
          key: 'delay_action',
          label: 'Delay Action',
          trClass: 'border-top-0',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'created_at',
          label: 'Waktu Dibuat',
          trClass: 'border-top-0',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
        {
          key: 'updated_at',
          label: 'Waktu Diupdate',
          trClass: 'border-top-0',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
        },
      ],
      itemsTicket: [],
      itemsResi: [],
      timeResponse: [],
      modes: 'single',
      selectMode: 'single',
      selected: true,

      // Date range picker
      YEAR_MONTH_DAY,
      picker: {
        startDate: firstDateOfMonth,
        endDate: lastDateOfMonth,
      },
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
        monthNames: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'Mei',
          'Jun',
          'Jul',
          'Agu',
          'Sep',
          'Okt',
          'Nov',
          'Des',
        ],
      },
      ranges: {
        'Semua ': [komshipDate, today],
        '7 Hari Terakhir': [last7, today],
        '30 Hari Terakhir': [last30, today],
        'Bulan Ini': [firstDateOfMonth, today],
      },
      rangesActivity: {
        'Hari ini ': [today, today],
        'Kemarin ': [last2, last2],
        '7 Hari Terakhir': [last7, today],
        'Bulan Ini': [firstDateOfMonth, today],
        'Custom Range': [null, null],
      },
      today,
      last7,
      last2,
      last30,
      firstDateOfMonth,
      lastDateOfMonth,
      komshipDate,
      imageFile: null,
      imageInitialFile: null,
      itemsImageInitialFile: [],
      belumDiProses: 0,
      perluTindakLanjut: 0,
      sedangDiProses: 0,
      // Validation
      required,

      // Store
      itemsNoResi: null,
      noResi: null,
      customerName: '',
      ticketTypeItems: [],
      ticketType: null,
      description: '',
      file: null,

      // Filter
      ticketStatus: [],
      ticketStatusItems: [
        {
          label: 'Menunggu Tindak Lanjut',
          value: 0,
          onCheck: false,
        },
        {
          label: 'Belum Diproses',
          value: 1,
          onCheck: false,
        },
        {
          label: 'Sedang Diproses',
          value: 2,
          onCheck: false,
        },
        {
          label: 'Selesai',
          value: 3,
          onCheck: false,
        },
        {
          label: 'Dibatalkan',
          value: 4,
          onCheck: false,
        },
      ],
      shippingItems: [],
      fontClassTicketStatus: {
        color: 'salmon',
      },
      search: '',
      filterTicketType: [],
      dateRange: {
        startDate: last60,
        endDate: today,
      },
      dateRangeUpdate: {
        startDate: last150,
        endDate: today,
      },
      dateRangeActivity: {
        startDate: today,
        endDate: today,
      },
      // Pagination
      currentPage: 1,
      totalRows: 0,
      optionsPage: [250, 500],
      totalPerPage: 250,
      dataCountShippment: {},
      loadingCountShippment: true,
      priorityItems: [
        {
          label: 'User Prioritas',
          value: 'UserPrioritas',
          isChecked: false,
        },
        {
          label: 'Urgent',
          value: 'Urgent',
          isChecked: false,
        },
      ],

      dropdownFilter: false,
      filterRequest: false,
      countPrioritas: 0,
      countUrgent: 0,
      countRequest: 0,
      expedition: [],
      isCollapsed: true,
      isAllType: true,
      perluDiProses: 0,
      finished: 0,
      received: 0,
    }
  },
  computed: {
    ...mapState('filter', ['expeditionList']),
  },
  watch: {
    dateRangeActivity: {
      handler() {
        this.fetchResponseTicket()
      },
    },
    currentPage: {
      handler() {
        this.fetchTicket()
      },
    },
    search(v) {
      if (v === '') {
        this.fetchTicket()
      }
    },
    isCollaborator(v) {
      if (v === true) {
        if (this.expeditionList.length === 0) {
          this.$store.dispatch('filter/getUpdateExpedition')
        }
        this.shippingItems = this.expeditionList.map(item => {
          if (item.shipping_name === 'J&T') {
            return 'JNT'
          }
          return item.shipping_name
        })
        this.fetchTicketType()
        this.formatDate()
        this.fetchTicketAll()
        this.fetchTicketMitraCount()
        this.fetchResponseTicket()
        this.ticketTypeItems.map(val => {
          if (val.onCheck === true) {
            this.filterTicketType.push(val.id)
          }
        })
      }
    },
  },
  beforeMount() {
    this.ticketStatusItems.map((val, index) => {
      if (val.onCheck === true) {
        this.ticketStatus.push(val.value)
      }
    })
  },
  methods: {
    fetchTicketMitraCount() {
      this.loadingCountShippment = false
      this.$http_collaborator.get('/ticket/api/v1/ticket/admin/count-ticket')
        .then(response => {
          this.perluDiProses = response.data.data.pending_shipment
          this.perluTindakLanjut = response.data.data.waiting_seller
          this.sedangDiProses = response.data.data.in_transit
          this.finished = response.data.data.completed
          this.received = response.data.data.successfully_received
        }).catch(err => {
          this.$toast_error({ message: 'Gagal Mengambil Data' })
          this.loadingCountShippment = false
        })
    },
    countFilter(params) {
      if (this.priorityItems[1].isChecked) {
        this.$http_komship
          .get('/v1/ticket-admin/count-urgent-admin', { params })
          .then(res => {
            const { data } = res.data
            this.countUrgent = data.total
          })
      }
      if (this.filterRequest) {
        this.$http_komship
          .get('/v1/ticket-admin/count-request-urgent-admin', { params })
          .then(res => {
            const { data } = res.data
            this.countRequest = data.total
          })
      }
      if (this.priorityItems[0].isChecked) {
        this.$http_komship
          .get('/v1/ticket-admin/count-user-prioritas-admin', { params })
          .then(res => {
            const { data } = res.data
            this.countPrioritas = data.total
          })
      }
    },
    setFilter(value) {
      this.priorityItems.forEach((item, index) => {
        if (item.value === value) {
          this.priorityItems[index].isChecked = !item.isChecked
        }
      })
      this.fetchTicket()
    },
    setFilterRequest() {
      this.filterRequest = !this.filterRequest
      if (this.filterRequest) {
        const findIndexProccessed = this.ticketStatusItems.findIndex(item => item.value === 1)
        const findIndexUnProccessed = this.ticketStatusItems.findIndex(item => item.value === 2)
        this.ticketStatusItems[findIndexProccessed].onCheck = true
        this.ticketStatusItems[findIndexUnProccessed].onCheck = true
        this.filterTicketByStatus(this.ticketStatusItems[findIndexProccessed])
        this.filterTicketByStatus(this.ticketStatusItems[findIndexUnProccessed])
      } else {
        const findIndexProccessed = this.ticketStatusItems.findIndex(item => item.value === 1)
        const findIndexUnProccessed = this.ticketStatusItems.findIndex(item => item.value === 2)
        this.ticketStatusItems[findIndexProccessed].onCheck = false
        this.ticketStatusItems[findIndexUnProccessed].onCheck = false
        this.filterTicketByStatus(this.ticketStatusItems[findIndexProccessed])
        this.filterTicketByStatus(this.ticketStatusItems[findIndexUnProccessed])
      }
      this.fetchTicket()
    },
    imgPlaceholder(e) {
      e.target.src = require('@/assets/images/avatars/image-null.png')
    },
    async fetchTicketAll() {
      this.loadingDataTable = true
      const params = {
        per_page: this.totalPerPage,
        page: this.currentPage,
      }
      Object.assign(params, {
        start_date: `${this.formatDateParams(this.dateRange.startDate)} 00:00:00`,
      })
      Object.assign(params, {
        end_date: `${this.formatDateParams(this.dateRange.endDate)} 23:59:59`,
      })
      await this.$http_collaborator.get('/ticket/api/v1/ticket/admin/list', { params })
        .then(response => {
          if (response.data.code !== 400) {
            this.itemsTicket = response.data.data.tickets
            this.totalRows = response.data.data.total_ticket
            this.loadingDataTable = false
            this.dropdownFilter = false
            this.countFilter(params)
          } else {
            this.itemsTicket = []
            this.loadingDataTable = false
          }
        })
        .catch(err => {
          this.itemsTicket = []
          this.$toast_error({ message: 'Gagal Mengambil Data' })
          this.loadingDataTable = false
        })
    },
    async fetchTicket() {
      this.loadingDataTable = true
      const params = {}

      Object.assign(params, { search: this.search })
      Object.assign(params, { status_ticket: this.ticketStatus.join() })
      Object.assign(params, { shipping: this.expedition.join(',') })
      Object.assign(params, { type_ticket_id: this.filterTicketType.join() })
      Object.assign(params, {
        start_date: `${this.formatDateParams(this.dateRange.startDate)} 00:00:00`,
      })
      Object.assign(params, {
        end_date: `${this.formatDateParams(this.dateRange.endDate)} 00:00:00`,
      })
      Object.assign(params, { per_page: this.totalPerPage })
      Object.assign(params, { page: this.currentPage })
      if (this.filterRequest) {
        Object.assign(params, { request_urgent: 1 })
      }
      const priority = this.priorityItems.filter(item => item.isChecked).map(item => item.value).join(',')
      if (priority !== '') {
        Object.assign(params, { prioritas: priority })
      }

      await this.$http_collaborator
        .get('/ticket/api/v1/ticket/admin/list', {
          params,
        })
        .then(response => {
          this.itemsTicket = response.data.data.tickets
          this.totalRows = response.data.data.total_ticket
          this.loadingDataTable = false
          this.dropdownFilter = false
          this.countFilter(params)
        })
        .catch(err => {
          this.$toast_error({ message: 'Gagal Mengambil Data' })

          this.itemsTicket = []
          this.loadingDataTable = false
        })
    },
    async fetchTicketType() {
      const params = {
        all: this.isAllType,
      }
      await this.$http_collaborator
        .get('/ticket/api/v1/ticket/mitra/type-ticket', { params })
        .then(response => {
          this.ticketTypeItems = response.data.data.types
          for (let x = 0; x < this.ticketTypeItems.length; x += 1) {
            Object.assign(this.ticketTypeItems[x], { onCheck: false })
          }
        })
        .catch(err => {
          this.$toast_error({ message: err })
          return []
        })
    },
    async fetchResponseTicket() {
      const params = {
        start_date: `${this.formatDateParams(this.dateRangeActivity.startDate)} 00:00:00`,
        end_date: `${this.formatDateParams(this.dateRangeActivity.endDate)} 23:59:59`,
      }
      await this.$http_collaborator
        .get('/ticket/api/v1/ticket/admin/respond-ticket', { params })
        .then(response => {
          this.timeResponse = response.data.data
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.$toast_error({ message: err })
          return []
        })
    },
    onRowSelected(data) {
      const selectedItem = data[0]

      const url = this.$router.resolve({
        path: `/tiket/kolaborator/detail/${selectedItem.no_ticket}`,
      })
      window.open(url.href, '_blank')
    },
    formatDate(d) {
      const ds = moment(d)
        .format('HH:mm D/MM/YYYY')
      const date = ds.split(' ')
      const newDate = date[1].split('/')
      const hour = `${date[0]} WIB`
      let bulan = ''

      // eslint-disable-next-line default-case
      switch (newDate[1]) {
        case '01':
          bulan = 'Januari'
          break
        case '02':
          bulan = 'Februari'
          break
        case '03':
          bulan = 'Maret'
          break
        case '04':
          bulan = 'April'
          break
        case '05':
          bulan = 'Mei'
          break
        case '06':
          bulan = 'Juni'
          break
        case '07':
          bulan = 'Juli'
          break
        case '08':
          bulan = 'Agustus'
          break
        case '09':
          bulan = 'September'
          break
        case '10':
          bulan = 'Oktober'
          break
        case '11':
          bulan = 'November'
          break
        case '12':
          bulan = 'Desember'
          break
      }

      return {
        hour: `${hour}`,
        date: `${newDate[0]} ${bulan} ${newDate[2]} `,
      }
    },
    formatDateParams(d) {
      return moment(d)
        .format('YYYY-MM-DD')
    },
    filterTicketByStatus(data) {
      const findIndexObj = this.ticketStatusItems.findIndex(
        items => items.value === data.value,
      )
      const findObj = this.ticketStatus.findIndex(items => items === data.value)
      if (!this.ticketStatus.includes(data.value)) {
        if (this.ticketStatusItems[findIndexObj].onCheck === true) {
          this.ticketStatus.push(data.value)
        }
      }

      if (this.ticketStatus.includes(data.value) && !this.ticketStatusItems[findIndexObj].onCheck) {
        this.ticketStatus.splice(findObj, 1)
      }
    },
    filterByTicketType(data) {
      const findIndexObj = this.ticketTypeItems.findIndex(
        items => items.id === data.id,
      )
      const findObj = this.filterTicketType.findIndex(
        items => items === data.id,
      )
      if (this.ticketTypeItems[findIndexObj].onCheck === true) {
        this.filterTicketType.push(data.id)
      } else {
        this.filterTicketType.splice(findObj, 1)
      }
    },
    checkDelayAction(value) {
      const data = value.delay_action
      if (data === null) {
        return '-'
      }
      return data
    },
    clearFilter() {
      if (this.search === '') {
        this.fetchTicketAll()
      }
      this.priorityItems = this.priorityItems.map(item => ({ ...item, isChecked: false }))
      this.ticketTypeItems = this.ticketTypeItems.map(item => ({ ...item, onCheck: false }))
      this.ticketStatusItems = this.ticketStatusItems.map(item => ({ ...item, onCheck: false }))
      this.expedition = []
      this.filterTicketType = []
      this.ticketStatus = []
      this.dateRange = {
        startDate: last60,
        endDate: today,
      }
      this.dateRangeUpdate = {
        startDate: last150,
        endDate: today,
      }
      this.filterRequest = false
    },
    statusTicketClass(data) {
      let resultVariant = ''
      if (data === 'Belum Diproses') {
        resultVariant = 'font-bold text-red-500'
      } else if (data === 'Sedang Diproses') {
        resultVariant = 'font-bold text-warning'
      } else if (data === 'Selesai') {
        resultVariant = 'font-bold text-success'
      } else if (data === 'Dibatalkan') {
        resultVariant = 'font-bold text-secondary'
      }
      return resultVariant
    },
    statusOrderClass(v) {
      let result = ''
      if (v === 'Diterima') {
        result = 'text-success'
      } else if (v === 'Dipacking' || v === 'Diajukan' || v === 'Dikirim') {
        result = 'text-warning'
      } else if (v === 'Batal' || v === 'Retur' || v === 'Hilang' || v === 'Rusak') {
        result = 'text-primary'
      }
      return result
    },
    statusOrderValue(v) {
      let result = ''
      if (v === 'Diterima') {
        result = '🙌🏻 akhirnya diterima'
      } else if (v === 'Dipacking') {
        result = v
      } else if (v === 'Diajukan') {
        result = v
      } else if (v === 'Batal') {
        result = 'Dibatalkan'
      } else if (v === 'Retur') {
        result = 'Berakhir Return'
      } else if (v === 'Hilang') {
        result = 'Paketnya Hilang 🙏'
      } else if (v === 'Rusak') {
        result = 'Paketnya Rusak 🙏'
      }
      return result
    },
    clearFieldTicket() {
      this.noResi = null
      this.itemsNoResi = null
      this.customerName = ''
      this.ticketType = null
      this.description = ''
      this.itemsImageInitialFile = []
    },
    setPerPage(page) {
      this.totalPerPage = page
      this.fetchTicket()
    },
    labelFilter(data) {
      return data.toString()
    },
    handleColorPercentage(value) {
      const numericValue = parseFloat(value)
      if (numericValue >= 90) {
        return 'text-[#34A770]'
      }
      return 'text-[#E31A1A]'
    },
  },
}

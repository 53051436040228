import { render, staticRenderFns } from "./ticket-partner.html?vue&type=template&id=6e803cfc&scoped=true&"
import script from "./ticket-partner.js?vue&type=script&lang=js&"
export * from "./ticket-partner.js?vue&type=script&lang=js&"
import style0 from "../Tiket.scss?vue&type=style&index=0&id=6e803cfc&lang=true&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e803cfc",
  null
  
)

export default component.exports
/* eslint-disable global-require */
/* eslint-disable array-callback-return */
import {
  BRow, BCol, BCard, BForm, BFormGroup,
} from 'bootstrap-vue'
import TicketPartner from './ticket-partner/ticket-partner.vue'
import TicketKolaborator from './ticket-collaborator/ticket-collaborator.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    TicketPartner,
    TicketKolaborator,
  },
  data() {
    return {
      isCollaborator: false,
    }
  },
  methods: {
    tabCollab(val) {
      if (val === 1) {
        this.isCollaborator = true
      } else if (val === 0) {
        this.isCollaborator = false
      }
    },
  },
}
